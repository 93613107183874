<template>
  <div class="pa-4">
    <p>
      You are now able to download your document(s) as well as access the
      Digital Vault feature of your account.
    </p>
    <div class="mb-4">
      <div class="text-label">What should you do now?</div>
      <div class="mb-2">After completing the questions in your account:</div>
      <div class="mb-2">
        <v-icon
          color="primary"
          small
          class="mx-4"
          >mdi-check-bold</v-icon
        >
        Download
      </div>
      <div class="mb-2">
        <v-icon
          color="primary"
          small
          class="mx-4"
          >mdi-check-bold</v-icon
        >
        Print
      </div>
      <div class="mb-4">
        <v-icon
          color="primary"
          small
          class="mx-4"
          >mdi-check-bold</v-icon
        >
        Sign
      </div>
      <div>
        The downloaded document(s) include extra information and instructions to
        help you with the process.
      </div>
    </div>

    <div
      v-if="products.includes('will')"
      class="mb-4"
    >
      <div class="text-label">Optional review by our legal team</div>
      <div>
        If you would like our legal team to review your Will, download it and
        send it to hello@willed.com.au.
      </div>
    </div>
    <div
      v-if="unclaimedPartner"
      class="mb-4"
    >
      <div class="text-label">Your partners account</div>
      <div>
        We have emailed you a special link your partner can use to create their
        account.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PostPurchaseInstructions',
  props: ['products'],
  computed: {
    unclaimedPartner() {
      let meta = this.$store.getters['account/user_meta_data']
      return meta?.partner && !meta.partner.claimed
    },
  },
}
</script>

<style></style>
