<template>
  <v-navigation-drawer
    width="500"
    temporary
    app
    right
    v-model="localOpen"
    id="user-menu"
  >
    <div class="header">
      <v-toolbar
        flat
        class="transparent"
        dense
      >
        <btn-outline
          icon="mdi-close"
          @click="localOpen = false"
        />
      </v-toolbar>
      <v-toolbar
        flat
        class="transparent"
        dense
      >
        <v-toolbar-title class="pb-2">
          <h2>Manage Your Account</h2>
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </div>
    <perfect-scrollbar class="content">
      <v-window
        v-model="window"
        touchless
      >
        <v-window-item
          v-for="item in menuItems"
          :value="item.key"
          :key="item.key"
        >
          <v-toolbar
            flat
            dense
          >
            <btn-outline
              icon="mdi-arrow-left"
              @click="window = 'default'"
              class="mr-4"
            />
            <v-toolbar-title>
              <h3>{{ item.title }}</h3>
            </v-toolbar-title>
          </v-toolbar>
          <component
            v-if="window !== 'default'"
            :is="`user-menu-${window}`"
            @close="onFormClose"
          ></component>
        </v-window-item>
        <v-window-item value="default">
          <v-list>
            <template v-for="(item, index) in menuItems">
              <v-list-item
                link
                :key="item.key"
                @click="onClickMenuItem(item)"
                v-if="typeof item === 'object'"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-lg font-weight-bold">{{
                    item.title
                  }}</v-list-item-title>
                  <v-list-item-subtitle v-if="subtitles[item.key]">{{
                    typeof subtitles[item.key] === 'function'
                      ? subtitles[item.key]()
                      : subtitles[item.key]
                  }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon color="primary">mdi-arrow-right</v-icon>
                </v-list-item-action>
              </v-list-item>
              <div
                class="divider"
                :key="`${item}-${index}`"
                v-else
              ></div>
            </template>
          </v-list>
          <div class="pa-4">
            <v-btn
              @click="onClickLogout"
              class="bg-grey-30 mx-auto d-block px-12"
              x-large
              text
            >
              <div class="d-flex align-center text-capitalize">
                Logout
                <v-icon
                  color="primary"
                  right
                  >mdi-export</v-icon
                >
              </div>
            </v-btn>
          </div>
        </v-window-item>
      </v-window>
    </perfect-scrollbar>
  </v-navigation-drawer>
</template>

<script>
import { isEmpty } from 'lodash'
import { mapGetters } from 'vuex'

import BtnOutline from '../ui/BtnOutline'

/**Menu Content Components */
const UserMenuChangeEmail = () => import('../form/usermenu/ChangeEmail')
const UserMenuChangePassword = () => import('../form/usermenu/ChangePassword')
const UserMenuDeleteAccount = () => import('../form/usermenu/DeleteAccount')
const UserMenuPreferences = () => import('../form/usermenu/Preferences')
const UserMenuSubscription = () => import('../form/usermenu/Subscription')

export default {
  name: 'UserMenu',
  components: {
    BtnOutline,
    UserMenuChangeEmail,
    UserMenuChangePassword,
    UserMenuDeleteAccount,
    UserMenuPreferences,
    UserMenuSubscription,
  },
  data: () => ({
    window: 'default',
    menus: [
      {
        key: 'subscription',
        title: 'Subscription',
      },
      {
        key: 'preferences',
        title: 'Preferences',
      },
      {
        key: 'change-email',
        title: 'Change Email',
      },
      {
        key: 'change-password',
        title: 'Change Password',
      },
      {
        key: 'delete-account',
        title: 'Delete account',
      },
    ],
  }),
  computed: {
    ...mapGetters('account', ['user_meta_data', 'user_access', 'auth_user']),
    selectedMenuItem() {
      return this.window === 'default'
        ? null
        : this.menuItems.find((x) => x.key === this.window)
    },
    userMenuAlert() {
      return this.$store.getters['app/user_menu_alert']
    },
    hasAlert() {
      return (
        !isEmpty(this.userMenuAlert) &&
        Object.values(this.userMenuAlert).every((val) => val !== null)
      )
    },
    localOpen: {
      get() {
        return this.$store.getters['app/user_menu']
      },
      set(val) {
        this.$store.commit('app/SET_USER_MENU', val)
      },
    },
    subtitles() {
      return {
        'change-email': this.auth_user.email,
        subscription: () => {
          const expiryDate = `${this.user_access.value.expiryDate.day}/${this.user_access.value.expiryDate.month}/${this.user_access.value.expiryDate.year}`
          if (this.user_access.isRenewing && this.user_access.isActive)
            return `Renews: ${expiryDate}`
          if (
            (!this.user_access.isRenewing && this.user_access.isActive) ||
            this.user_access.isGracePeriod
          ) {
            return `Expiry: ${expiryDate} - No Subscription`
          }
        },
      }
    },
    menuItems() {
      const items = []
      let i = 0
      this.menus.forEach((x) => {
        if (
          x.key === 'preferences' &&
          typeof this.user_meta_data.shareWithCharities !== 'boolean'
        )
          return

        if (
          x.key === 'subscription' &&
          ((this.user_access.hasExpired && !this.user_access.isGracePeriod) ||
            !this.user_access.value.expiryDate.day)
        )
          return

        items.push(x)
        i += i
        if (i % 2 === 0) items.push('divider')
      })

      return items
    },
  },
  methods: {
    onFormClose() {
      this.window = 'default'
    },
    onClickMenuItem(item) {
      this.window = item.key
    },
    onClickLogout() {
      this.$store.dispatch('account/auth_logout').then(() => {
        this.$router.push('/login')
      })
    },
  },
}
</script>

<style lang="scss">
#user-menu {
  .header {
    background-image: url('~@/v2/assets/images/bg-curve.svg');
    background-size: cover;
    height: 124px;
  }
  .content {
    position: absolute;
    top: 124px;
    bottom: 0px;
    left: 0px;
    right: 0px;
  }
}
</style>
