<template>
  <div id="app-nav">
    <div
      v-for="item in links"
      :key="item.section"
      @click="
        $route.meta.section === item.section
          ? null
          : $router.push({ name: item.name })
      "
      class="app-nav-link"
      :class="{ active: $route.meta.section === item.section }"
    >
      {{ item.label }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AppNav',
  data: () => ({}),
  computed: {
    ...mapGetters('account', ['user_address']),
    ...mapGetters('app', ['poa_states']),
    showPoa() {
      return this.poa_states.includes(this.user_address.value.state)
    },
    links() {
      let menu = [
        {
          section: 'will',
          name: 'Will',
          label: 'Will',
        },
      ]
      if (this.showPoa) {
        menu.push({
          section: 'poa',
          name: 'Poa',
          label: 'POA',
        })
      }
      menu.push({
        section: 'vault',
        name: 'Vault',
        label: 'Vault',
      })
      menu.push({
        section: 'wishes',
        name: 'Wishes',
        label: 'Funeral',
      })
      return menu
    },
  },
}
</script>

<style lang="scss">
#app-nav {
  display: flex;
  .app-nav-link {
    padding: 4px 0px 4px;
    border-bottom: 2px solid transparent;
    margin: 0 16px;
    font-weight: 500;
    cursor: pointer;
    position: relative;
    &:after,
    &.active:after {
      border-radius: 3px;
      content: '';
      display: block;
      width: 0;
      height: 3px;
      background-color: var(--primary);
      transition: width 0.5s;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    &:hover:after,
    &.active:after {
      width: 100% !important;
    }
    font-size: 1.25rem;
  }
}
</style>
