<template>
  <button
    @click="$emit('click')"
    class="outline-btn"
    :class="{ disabled: disabled, 'type-icon': !!icon, 'type-text': !!text }"
    :disabled="disabled || loading"
  >
    <div
      v-if="icon"
      class="icon"
    >
      <v-icon>{{ icon }}</v-icon>
    </div>
    <div
      v-if="text"
      class="text"
    >
      {{ text }}
    </div>
    <v-fade-transition>
      <v-overlay
        v-if="loading"
        absolute
        color="primary"
        opacity="1"
      >
        <v-progress-circular
          :width="3"
          color="black"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
    </v-fade-transition>
  </button>
</template>

<script>
export default {
  name: 'BtnOutline',
  props: {
    loading: {
      default: false,
    },
    disabled: {
      default: false,
    },
    icon: {
      default: 'mdi-close',
    },
    text: {
      default: null,
    },
  },
  data: () => ({}),
  computed: {
    hasTextSlot() {
      return !!this.$slots.default
    },
  },
}
</script>

<style lang="scss">
.outline-btn {
  height: 36px;
  border-radius: 4px;
  border: 2px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  overflow: hidden;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.1) 50%,
    transparent 50%
  );
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.5s ease-out;
  position: relative;
  &:hover:not(:disabled) {
    background-position: left bottom;
    cursor: pointer;
  }
  &.disabled {
    opacity: 0.75;
    cursor: not-allowed;
  }
  .text {
    padding: 0 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 1.1rem;
  }
  &.type-icon .text {
    padding: 0 6px 0 0;
  }
  .icon {
    width: 32px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      color: #000000;
    }
  }
}
</style>
